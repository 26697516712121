const LOGIN_USER = 'LOGIN_USER';
const LOGIN_ADMIN = 'LOGIN_ADMIN';
const LOGIN_COMPANY = 'LOGIN_COMPANY';
const LOGIN_SHIPPOSELLER = "LOGIN_SHIPPOSELLER"
const REMOVE_SHIPPOSELLER = "REMOVE_SHIPPOSELLER"
const REMOVE_USER = 'REMOVE_USER';
const REMOVE_ADMIN = 'REMOVE_ADMIN';
const REMOVE_COMPANY = 'REMOVE_COMPANY';
const COLLAPSED = 'COLLAPSED';
const MODELS = 'MODELS';
const MATERIALS = 'MATERIALS';
const ACTIVE_ITEM = 'ACTIVE_ITEM';
const ACTIVE_COMPONENT = 'ACTIVE_COMPONENT';
const OPEN_DRAWER = 'OPEN_DRAWER';
const SELECTED_PLAN = 'SELECTED_PLAN';
const ONSHAPE_DOCUMENT = 'ONSHAPE_DOCUMENT';
const REMOVE_ONSHAPE_DOCUMENT = 'REMOVE_ONSHAPE_DOCUMENT';
const GET_CLICKED_NOTIFICATION_INFO = 'GET_CLICKED_NOTIFICATION_INFO';
const CLEAR_CLICKED_NOTIFICATION_INFO = 'CLEAR_CLICKED_NOTIFICATION_INFO';
export {
  LOGIN_USER,
  LOGIN_ADMIN,
  REMOVE_ADMIN,
  LOGIN_COMPANY,
  LOGIN_SHIPPOSELLER,
  REMOVE_SHIPPOSELLER,
  REMOVE_USER,
  REMOVE_COMPANY,
  COLLAPSED,
  MODELS,
  MATERIALS,
  ACTIVE_ITEM,
  ACTIVE_COMPONENT,
  OPEN_DRAWER,
  SELECTED_PLAN,
  ONSHAPE_DOCUMENT,
  REMOVE_ONSHAPE_DOCUMENT,
  GET_CLICKED_NOTIFICATION_INFO,
  CLEAR_CLICKED_NOTIFICATION_INFO
};
