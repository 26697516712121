import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// assets
import { EditOutlined, LogoutOutlined } from '@ant-design/icons';
import { allPaths } from '../../../../../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = ({ handleLogout }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state.adminAuthReducer);
  
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index, route) => {
    setSelectedIndex(index);
    navigate(route);
  };

  const isAdmin = admin?.userRole === 1;
  const hasOnShapeUserDetails = admin?.onShapeUserDetails && Object.keys(admin.onShapeUserDetails).length > 0;
  const hasPermissionGroup = admin?.permissionGroup && !admin.permissionGroup.includes('onshape');

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event) => handleListItemClick(event, 0, isAdmin ? allPaths.ADMIN.PROFILE : allPaths.SELLER.MY_PROFILE)}
      >
        <ListItemIcon>
          <EditOutlined />
        </ListItemIcon>
        <ListItemText primary="Edit Profile" />
      </ListItemButton>

      {!hasOnShapeUserDetails && (
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => handleListItemClick(event, 1, isAdmin ? allPaths.ADMIN.CHANGE_PASSWORD : allPaths.SELLER.UPDATE_PASSWORD)}
        >
          <ListItemIcon>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary="Update Password" />
        </ListItemButton>
      )}

      {(isAdmin || hasPermissionGroup) && (
        <ListItemButton selected={selectedIndex === 2} onClick={handleLogout}>
          <ListItemIcon>
            <LogoutOutlined />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      )}
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
